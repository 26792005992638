:root {
  --main-font-color: #9699a2;
  --chapter-font-color: #ffb822;
  --primary-bg-color: #0694f1;
  --secondary-bg-color: #25272d;
  --secondary-border-color: #40424d;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.lh-20 {
  line-height: 20px !important;
}

.chapter hr {
  margin: 40px 0 0 0;
  padding: 0;
  height: 1px;
  border: none;
  background-color: var(--chapter-font-color);
}

.content-container .chapter-header {
  margin: 40px 0;
  color: var(--chapter-font-color);
  font-size: 24px;
  text-transform: uppercase;
}

.img-container {
  width: 100%;
  margin: 33px 0 0 0;
  padding: 0;
}

.img-container img {
  display: block;
  margin: 0 auto;
  padding: 0;
  min-width: 484px;
  width: 54%;
  box-shadow: 0 2px 10px #111111;
}

.img-container figcaption {
  text-align: center;
  font-weight: 300;
  font-style: italic;
  font-size: 14px;
  color: var(--main-font-color);
  margin-top: 6px;
}

.steps-container {
  background-color: var(--secondary-bg-color);
  border: 1px solid var(--secondary-border-color);
  margin: 40px 0 0 0;
  padding: 20px;
}

.steps {
  font-size: 14px;
}

.steps ol,
.steps ul,
.steps p {
  color: #ffffff;
  padding: 0;
  margin: 0;
}

.steps li {
  margin-left: 16px;
  color: #ffffff;
}

.steps span {
  color: var(--main-font-color);
}

.sub-chapter {
  margin: 40px 0 0 0;
}

.sub-chapter hr {
  margin: 0;
  padding: 0;
  height: 1px;
  border: none;
  background-color: var(--secondary-border-color);
}

.sub-chapter h2 {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  margin: 40px 0 0 0;
  padding: 0;
  text-transform: uppercase;
}

.sub-chapter .info-description {
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: var(--main-font-color);
}

a {
  text-decoration: underline;
  color: var(--chapter-font-color);
}

.endpoint-body-container .description {
  color: var(--main-font-color);
  margin: 0 0 10px 0;
}

.endpoint-header-container {
  margin-top: 10px;
}

.sub-chapter > .endpoint-header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border: 1px solid var(--primary-bg-color);
  border-radius: 2px;
}

.sub-chapter .url {
  border: none;
}

.endpoint-body-container {
  padding: 20px;
  margin: 19px 0 0 0;
  background-color: var(--secondary-bg-color);
  border: 1px solid var(--secondary-border-color);
}

.endpoint-body-container .description {
  margin: 0;
}

.response {
  padding: 0;
  overflow-y: auto;
}

.response p {
  word-wrap: break-word;
  line-height: 21px;
}

.example-container {
  padding: 17px 22px 22px 20px;
  border: 1px solid var(--secondary-border-color);
}

.example-container .endpoint-body-container {
  padding: 0 20px 21px 20px;
  border-left: 1px solid var(--secondary-border-color);
  border-bottom: 1px solid var(--secondary-border-color);
  border-right: 1px solid var(--secondary-border-color);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.example p {
  margin: 0;
}

.example .method {
  background-color: var(--secondary-border-color);
  border-top: 1px solid var(--secondary-border-color);
  border-left: 1px solid var(--secondary-border-color);
  border-bottom: 1px solid var(--secondary-border-color);
  color: var(--main-font-color);
  font-size: 14px;
}

.example .url {
  background-color: #2b2d34;
  border: 1px solid var(--secondary-border-color);
  border-top-right-radius: 2px;
  width: 100%;
  padding-left: 13px;
  color: var(--main-font-color);
  font-size: 14px;
}

.content-container {
  padding: 16px 233px;
  line-height: 30px;
}

.content-container p span {
  text-decoration: underline;
  color: #ffb822;
}

.content-description {
  margin: 0 0 35px 0;
}

.content-container h2 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
}

p.endpoint-description {
  color: #8f939b;
  margin-top: 0;
}

.swagger-banner {
    padding: 20px 0px 20px 0px;
    background-color: #40424D;
    box-shadow: 0px 6px 5px -1px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 0px;
}
