.helpModal-container {
  background-color: rgba(71, 228, 9, 0.521);
  color: #fff;
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 5vh;
  right: 5vh;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 7px rgba(255, 255, 255, 0.3);
  -moz-box-shadow: 0 2px 7px rgba(255, 255, 255, 0.3);
  box-shadow: 0 2px 7px rgba(255, 255, 255, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  transform:scale(1);
  animation:unfoldOut 1s .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) 
}