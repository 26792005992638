// FIXME: need to remove !important

// ==== Variables ==== //
$yellow: #ffb822;
$black: #212529;
$grey: #c1c3cd;
$grey-color: #959ba2;
$grey-background: #25272d;
$grey-header: #c6c8d2;
$grey-input: #40424D;
$red-required: #ff2c2c;

// ==== Header ==== //
.info {
  .title {
    color: #fff !important;
    font-weight: 500 !important;
    margin-bottom: 5px !important;

    display: flex;
    flex-direction: row;
    max-width: 570px;

    small {
      background-color: $yellow !important;
      margin-bottom: 25px !important;

      .version {
        font-family: Poppins, sans-serif !important;
        width: 45px;
        height: 25px;
        text-align: center;
        padding-top: 2px !important;
        font-weight: normal !important;
      }
    }
  }

  .link {
    width: max-content !important;
    display: block;
    font-family: "Poppins", sans-serif !important;

    .url {
      border: none;
      color: $yellow !important;
      margin-left: -12px !important;
    }  
  }

  .base-url {
    color: $grey !important;
    font-family: "Poppins", sans-serif !important;
  } 

  .description {
    .markdown p {
      color: #fff !important;
      font-weight: 400 !important;
      margin-top: 40px !important;
      font-size: 14px !important;
      font-family: "Poppins", sans-serif !important;
    }
  }
}

// ==== Auth block ==== //
.scheme-container {
  background-color: $black !important;
  height: 90px !important;

  .schemes-title {
    color: #fff !important;
    opacity: 0.5;
    font-weight: 600 !important;
    font-family: "Poppins", sans-serif !important;
  }

  .schemes {
    select {
      background-color: $black !important;
      border: 1px solid $grey !important;
      color: #fff !important;
      font-weight: 500 !important;
      opacity: 0.8;
      background-image: url('./../../views/icons/down-arrow.svg') !important;
      background-size: 18px !important;
    }
  }

    .btn.authorize {
    border: none !important;
    background-color: $yellow !important ;
    color: #fff !important;
    border-radius: 20px !important;
    font-weight: 400 !important;
    font-family: "Poppins", sans-serif !important;

    svg {
      fill: #fff !important;
    }
  }
}

// ==== Filter ==== //
.filter-container {

  .filter {
    width: 100% !important;

    input {
      background-color: $grey-background !important;
      -webkit-text-fill-color: #fff;
      caret-color: #fff;
      opacity: 0.5 !important;
      border: 1px solid $grey-color !important;
      min-width: 100% !important;

      &:focus-visible {
        outline: none !important;
      }
    } 
  }
}

// ==== Loader ==== //
.loading-container {
  .loading {
    &::after {
      color: $yellow !important;
    }
    &::before {
      color: $yellow !important;
      border: 2px solid $yellow;
      border-top-color: $yellow !important;
    }
  }
}

// ==== Main block ==== //
#large-arrow-up {
  fill: $yellow !important;
}

#large-arrow-down {
  fill: $yellow !important;
}

#unlocked {
  fill: #fff !important;
}

.wrapper {
  // ==== No operations defined block ==== //
  section h3 {
    color: #fff !important;
    opacity: 0.5 !important;
  }
  
  // ==== Operations defined block ==== //
  .opblock-tag-section {
    h3 {
      .nostyle span {
        color: #fff !important;
        font-family: "Poppins", sans-serif !important;
        font-weight: 400 !important;
      }

      &:hover {
        background-color: #40424d3b !important;
        border-top-left-radius: 8px !important;
        border-top-right-radius: 8px !important;
      }
    }

    .operation-tag-content {
      .opblock {
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;

        .opblock-summary  {
          background-color: $grey-input !important;
          padding: 0px !important;
  
          .opblock-summary-control {
  
            &:focus {
              outline: none !important;
            }

            .opblock-summary-method {
              border-radius: 0px !important;
              font-weight: 600 !important;
              padding: 10px !important;
              font-family: "Poppins", sans-serif !important;
            }
  
            .opblock-summary-path {
              color: #fff !important;
              font-family: "Poppins", sans-serif !important;

              .nostyle span {
                font-weight: 400 !important;
              }
            }
  
            .opblock-summary-description {
              color: #fff !important;
              font-family: "Poppins", sans-serif !important;
            }

            .arrow {
              height: 13px !important;
            }
          }
  
          .authorization__btn {
            padding-right: 10px !important;
          }
          .authorization__btn.locked {
            padding-right: 10px !important;

            svg {
              fill: rgba(255, 255, 255, 0.5) !important
            }
          }
        }

        .opblock-body {
          background-color: #2D3037 !important;

          // general description to url
          .opblock-description-wrapper {
            .markdown p {
              color: #fff !important;
              font-family: "Poppins", sans-serif !important;
            }
          }

          .opblock-section {
            .opblock-section-header {
              background-color: $grey-background !important;

              .tab-header .opblock-title {
                font-weight: 500 !important;
                color: $grey-header !important;
              }

              .try-out button {
                border: $yellow 1px solid !important;
                color: #fff !important;
                border-radius: 20px !important;
                font-weight: 500 !important;
              }

              .btn.try-out__btn:hover {
                background-color: $yellow !important ;
              }

              .btn.try-out__btn.cancel {
                border: $grey-color 1px solid !important;
                font-weight: 500 !important;

                &:hover {
                  background-color: #40424d3b !important;
                }
              }
            }

            .parameters-container {
              .parameters thead {
                border-bottom-color: rgb(64, 66, 77) !important;
                border-bottom-style: solid !important;
                border-bottom-width: 1px !important;
              }

              .col_header.parameters-col_name {
                color: $grey-color !important;
                font-weight: 500 !important;
                font-size: 11px !important;
                font-family: "Poppins", sans-serif !important;
              }

              .col_header.parameters-col_description {
                color: $grey-color !important;
                border-bottom-color: $grey-input !important;
                font-weight: 500 !important;
                font-size: 11px !important;
                font-family: "Poppins", sans-serif !important;
              }

              .parameters-col_name {
                display: block;
                margin-right: 40px !important;

                .parameter__name.required, .parameter__name {
                  color: #fff !important;
                  font-weight: 400 !important;
                  font-family: "Poppins", sans-serif !important;

                  span {
                    color: #FF1C3C !important;
                    position: relative;
                    top: -7px !important;
                    left: 4px !important;
                    font-size: 11px !important;
                    font-family: "Poppins", sans-serif !important;
                  }
                  
                  &::after {
                    color: #FF1C3C !important;
                    top: -9px !important;
                    font-size: 9px !important;
                    font-weight: 500 !important;
                  }
                }

                .parameter__type {
                  color: #fff !important;
                  font-weight: 400 !important;
                  font-family: "Poppins", sans-serif !important;

                  .prop-format {
                    color:$grey-color !important;
                    margin-left: 5px !important;
                    font-family: "Poppins", sans-serif !important;
                  }
                }

                .parameter__in {
                  color: $grey-color !important;
                  font-weight: 300 !important;
                  font-style: normal !important;
                  font-family: "Poppins", sans-serif !important;
                }
              }

              .parameters-col_description {
                input {
                  background-color: $grey-input !important;
                  border: none !important;
                  border-radius: 2px !important;
                  font-weight: 400 !important;
                  margin-bottom: 25px !important;
                  color: $grey-header !important;
                }

                .invalid {
                  background-color: #FF2C2C1A !important;
                  border: 1px solid $red-required !important;
                  font-family: "Poppins", sans-serif !important;
                }
                .markdown p {
                  color: #fff !important;
                  margin-bottom: 5px !important;
                  font-family: "Poppins", sans-serif !important;
                }

                select {
                  background-color: $grey-background !important;
                  color: #fff !important;
                  font-weight: 400 !important;
                  border: 1px solid $grey-color !important;
                  background-image: url('./../../views/icons/down-arrow.svg') !important;
                  background-size: 15px !important;
                  font-family: "Poppins", sans-serif !important;
                }

                .model-example {
                  .tabitem {
                    color: $grey-color !important;

                    &:nth-child(2) {
                      margin-left: 2px !important;
                    }

                    &:first-child button {
                      margin-right: 2px !important;
                    }

                    &:first-child::after {
                      background: $grey-color !important;
                      height: 14px !important;
                      top: 3px !important;
                    }
                    
                  }

                  .tabitem.active {
                    color: $grey-header !important;
                  }

                  .tab {
                    margin: 20px 0px 20px -15px !important;
                  }

                  .highlight-code {
                    pre {
                      color: $grey-color !important;
                      border-radius: 5px !important;
                      background-color: $grey-background !important;

                      .hljs-attr + span {
                        margin-right: 5px !important;
                      }
                    }
                  }

                  .body-param__text {
                    background: $grey-input !important;
                    color: $grey-color !important;
                  }
                  
                  .body-param-options {
                    margin-top: 10px !important;

                    select {
                      font-family: "Poppins", sans-serif !important;
                    }

                    .btn.edit.body-param__example-edit.button{
                      border: $grey-color 1px solid !important;
                      background-color: $grey-background !important;
                      color: $grey-color !important;
                      border-radius: 25px !important;

                      &:hover {
                        background-color: #40424d3b !important;
                      }

                    }
                    
                    .btn.cancel.body-param__example-edit.button {
                      border: $red-required 1px solid !important;
                      background-color: $grey-background !important;
                      color: $red-required !important;
                      border-radius: 25px !important;

                      &:hover {
                        background-color: #FF2C2C1A !important;
                      }

                    }
                    span {
                      color: $grey-color !important;
                      font-weight: 500 !important;
                      margin-bottom: 10px !important;
                    }

                    .content-type-wrapper.body-param-content-type {
                      margin-top: 10px !important;

                      select {
                        background-color: $grey-background !important;
                        color: #fff !important;
                        font-weight: 400 !important;
                        border: 1px solid $grey-color !important;
                        background-image: url('./../../views/icons/down-arrow.svg') !important;
                        background-size: 15px !important;
                      }
                    }
                  }

                  .model-box {
                    background-color: $grey-background !important;
                    width: 100% !important;
                    font-family: "Poppins", sans-serif !important;
                    
                    .model {
                      color: $grey-color !important;

                      .brace-open::before {
                        content: "\a";
                        white-space: pre;
                      } 

                      .model-box-control {
                        color: $grey-color !important;

                        &:focus {
                          outline: none !important;
                        }

                        .pointer .model-title__text {
                          color: $grey-color !important;
                          font-family: "Poppins", sans-serif !important;
                        }

                        .model-toggle::after {
                          background-image: url('./../../views/icons/down-arrow.svg') !important;
                          background-size: 15px !important;
                        }
                      }

                      .property-row {
                        margin-bottom: 15px !important;
                      }
                    }
                  }
                }
            }
            }
          }

          .execute-wrapper {
            margin-top: -15px !important;

            .execute.opblock-control__btn {
              border: $yellow 1px solid !important;
              color: #fff !important;
              border-radius: 20px !important;
              font-weight: 500 !important;
              background-color: $yellow !important;
              font-family: "Poppins", sans-serif !important;
            }
          }

          .btn-group {
            margin-top: -15px !important;

            .execute.opblock-control__btn {
              border: $yellow 1px solid !important;
              margin-right: 10px !important;
              border-radius: 30px !important;
              background-color: $yellow !important;
              font-family: "Poppins", sans-serif !important;
              font-weight: 500 !important;
            }

            .btn-clear.opblock-control__btn {
              border: $grey-color 1px solid !important;
              margin-left: 10px !important;
              border-radius: 30px !important;
              color: #fff !important;
              font-family: "Poppins", sans-serif !important;
              font-weight: 500 !important;

              &:hover {
                background-color: #40424d3b !important;
                font-family: "Poppins", sans-serif !important;
                font-weight: 500 !important;
              }
            }
          }

          .responses-wrapper {
            .opblock-section-header {
              background-color: $grey-background !important;
              
              h4 {
                color: $grey-header !important;
                font-weight: 500 !important;
                font-family: "Poppins", sans-serif !important;
              }

              label {
                span {
                  color: $grey-color !important;
                  font-weight: 400 !important;
                  font-family: "Poppins", sans-serif !important;
                }

                select {
                  background-color: $grey-background !important;
                  color: #fff !important;
                  font-weight: 400 !important;
                  border: 1px solid $grey-color !important;
                  background-image: url('./../../views/icons/down-arrow.svg') !important;
                  background-size: 15px !important;
                  font-family: "Poppins", sans-serif !important;
                }
              }
            }

            .responses-inner {
              h4 {
                color: $grey-color !important;
                font-size: 11px !important;
                font-family: "Poppins", sans-serif !important;
                font-weight: 500 !important;
              }

              .microlight{
                font-weight: 500 !important;
                background-color: $grey-background !important;
              }

              .curl-command {
                .curl {
                  color: $grey-color !important;
                  background-color: $grey-background !important;
                  font-family: "Poppins", sans-serif !important;
                  font-weight: 500 !important;
                }


                .copy-to-clipboard {
                  background-color: transparent !important;
                  padding-top: 5px !important;
                }
              }

              thead {
                border-bottom-color: $grey-input !important;
                border-bottom-style: solid !important;
                border-bottom-width: 1px !important;

                .responses-header {
                  .col_header.response-col_status {
                    color: $grey-color !important;
                    display: block;
                    margin-right: 40px !important;    
                    font-size: 11px !important;
                    font-weight: 500 !important;
                    font-family: "Poppins", sans-serif !important;
                  }

                  .col_header.response-col_description {
                    color: $grey-color !important;
                    border-bottom-color: $grey-input !important;
                    font-weight: 500 !important;
                    font-size: 11px !important;
                    font-family: "Poppins", sans-serif !important;
                  }
                }
              }

              .response {
                background-color: #2D3037 !important;  
                
                .response-col_description {

                  h5 {
                    color: rgba(255, 255, 255, 0.5) !important;
                    font-family: "Poppins", sans-serif !important;
                    font-weight: 400 !important;
                  }
                  p {
                    color: #fff !important;
                    font-family: "Poppins", sans-serif !important;
                  }
                  // description of response
                  .response-col_description__inner {

                    p {
                      color: #fff !important;
                      font-family: "Poppins", sans-serif !important;
                    }
                  } 

                  .model-example {
                    .tabitem {
                      color: $grey-color !important;
                      font-family: "Poppins", sans-serif !important;
                      font-weight: 500 !important;

                      &:nth-child(2) {
                        margin-left: 2px !important;
                      }

                      &:first-child button {
                        margin-right: 2px !important;
                      }

                      &:first-child::after {
                        background: $grey-color !important;
                        height: 14px !important;
                        top: 3px !important;
                      }
                      
                    }
                    .tabitem.active {
                      color: $grey-header !important;
                    }
                    .tab {
                      margin: 20px 0px 20px -15px !important;
                    }

                    .highlight-code {
                      pre {
                        color: $grey-color !important;
                        border-radius: 5px !important;
                        background-color: $grey-background !important;
                        font-family: "Poppins", sans-serif !important;
                        font-weight: 500 !important;

                        .hljs-attr + span {
                          margin-right: 5px !important;
                        }
                      }
                    }

                    .model-box {
                      background-color: $grey-background !important;
                      width: 100% !important;
                      font-family: "Poppins", sans-serif !important;
                      
                      .model {
                        color: $grey-color !important;
                        font-family: "Poppins", sans-serif !important;
                        
                        .brace-open::before {
                          content: "\a";
                          white-space: pre;
                        } 

                        .model-box-control {
                          color: $grey-color !important;

                          &:focus {
                            outline: none !important;
                          }

                          .pointer .model-title__text {
                            color: $grey-color !important;
                            font-family: "Poppins", sans-serif !important;
                          }

                          .model-toggle::after {
                            background-image: url('./../../views/icons/down-arrow.svg') !important;
                            background-size: 15px !important;
                          }

                        }
                        .property-row {
                          margin-bottom: 15px !important;
                          display: block !important;
                        }
                      }
                    }
                  }
                }
                .response-col_status {
                  display: block;
                  margin-right: 94px !important;
                  font-size: 15px !important;
                  color: #fff !important;
                  font-family: "Poppins", sans-serif !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

// ==== Models ==== //
.models.is-open {
  display: none !important;
}

// ==== ModalAuth ==== //
.dialog-ux {
  .modal-ux {
    box-shadow: 0px 10px 30px #0000004D !important;
    border: none !important;
    width: 530px !important;
    height: 425px !important;
    background: none !important;

    .modal-dialog-ux {
      .modal-ux-inner {
        .modal-ux-header {
          background-color: $grey-background !important ;
          border: none !important;
          padding: 40px !important;

          h3 {
            color: $yellow !important;
            text-transform: uppercase;
            font-size: 23px !important;
            font-family: "Poppins", sans-serif !important;
          }

          .close-modal {
            display: none !important;
          }
        }

        .modal-ux-content {
          background-color: #2B2D34 !important;
        }

          h4, h6 {
            color: #fff !important;
            margin-top: -5px !important;
            font-weight: 500 !important;
            font-family: "Poppins", sans-serif !important;
          }

          .wrapper {
            label {
              color: $yellow !important;
              font-weight: 500 !important;
              font-family: "Poppins", sans-serif !important;
            }

            code {
              color: #fff !important;
              font-family: "Poppins", sans-serif !important;
              font-weight: 500 !important;
              padding-left: 15px !important;
            }

            section input[name="username"] {
              margin-bottom: 15px !important;
            }
            
            section input {
              background-color: $grey-background !important;
              border: none !important;
              min-width: 100% !important;
              font-size: 13px;
              font-family: "Poppins", sans-serif !important;
              caret-color: #fff;
              -webkit-text-fill-color: #fff;
            
              &::-webkit-input-placeholder {
                color: #fff !important;
              } /* Chrome/Opera/Safari */
              &::-moz-placeholder {
                color: #fff !important;
              } /* Firefox 19+ */
              &:-ms-input-placeholder {
                color: #fff !important;
                } /* IE 10+ */
              &:-moz-placeholder {
                color: #fff !important;
              }  /* Firefox 18- */
            }
          }

          .auth-btn-wrapper {
            margin-top: 30px;
            margin-left: 220px !important;
          
            .btn.modal-btn.auth.authorize.button {
              border: $yellow 1px solid !important;
              background-color: $grey-background !important ;
              color: #fff !important;
              border-radius: 20px !important;
              font-weight: 400 !important;
              text-transform: uppercase !important;
              font-family: "Poppins", sans-serif !important;
          
              &:hover {
                background-color: $yellow !important ;
                border: $yellow 1px solid !important;
              }
            }

            .btn.modal-btn.auth.button {
              border: $red-required 1px solid !important;
              color: $red-required !important;
              border-radius: 20px !important;
              font-weight: 400 !important;
              text-transform: uppercase !important;
              font-family: "Poppins", sans-serif !important;
          
              &:hover {
                background-color: $red-required !important ;
                border: $red-required 1px solid !important;
                color: #fff !important;
              }
            }

            .btn.modal-btn.auth.btn-done.button {
              border: #fff 1px solid !important;
              width: 95px !important;
              height: 39px !important;
              border-radius: 156px !important;
              text-transform: uppercase !important;
              color: #fff !important;
              font-weight: 400 !important;
              margin-left: 20px !important;
              font-family: "Poppins", sans-serif !important;

              &:hover {
                background-color: #fff !important ;
                border: #fff 1px solid !important;
                color: #2D3037 !important;
                margin-left: 20px !important;
              }
            }
          }
        }
      }
    }
}