.endpoint-container {
  margin: 40px 0 0 0;
  padding: 0;
}

.endpoint-container hr {
  margin: 0;
  padding: 0;
  height: 1px;
  border: none;
  background-color: var(--secondary-border-color);
}

.endpoint-header-container {
  background-color: #40424d;
  display: flex;
}

.endpoint-name {
  margin: 0;
  margin-top: 40px;
}

.endpoint-body-container {
  padding: 0 20px 21px 20px;
  border-left: 1px solid #0694f1;
  border-bottom: 1px solid #0694f1;
  border-right: 1px solid #0694f1;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

p.endpoint-description {
  color: #8f939b;
  margin-top: 0;
}

.method {
  margin-left: -1px;
  background-color: #0694f1;
  border-top: 1px solid #0694f1;
  border-left: 1px solid #0694f1;
  border-bottom: 1px solid #0694f1;
  border-top-left-radius: 2px;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  height: 38px;
}

.url {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 13px;
  border-top-right-radius: 2px;
  font-size: 14px;
  color: #fff;
  line-height: 21px;
  border: 1px solid #0694f1;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title p {
  font-weight: 600;
  text-transform: uppercase;
  color: #9699a2;
  margin: 4px 0 0 0;
}

.title span {
  color: #ffb822;
  cursor: pointer;
}

.response {
  background-color: #25272d;
  padding: 16px 18px 16px 18px;
  color: #9699a2;
  max-height: 420px;
  overflow-y: auto;
}

.response::-webkit-scrollbar {
  width: 6px;
}

.response::-webkit-scrollbar-track {
  background-color: initial;
}

.response::-webkit-scrollbar-thumb {
  background-color: #40424d;
  border-radius: 20px;
}

.response p {
  margin: 0;
  color: #9699a2;
  font-size: 14px;
}

.endpoint-body-container .description {
  color: #9699a2;
  margin: 0 0 10px 0;
  line-height: 30px;
}

.query span,
.params span {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #9699a2;
  margin: 4px 0 0 0;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.ml-120 {
  margin-left: 120px !important;
}

.fieldName {
  font-weight: 600;
}

ul {
  padding: 0;
  margin: 0;
  color: #9699a2;
  font-size: 14px;
  margin-left: 16px;
  list-style: none;
  margin-bottom: 5px;
}

li {
  margin-left: 16px;
  line-height: 20px;
  font-variant-numeric: tabular-nums;
}
