.header-container {
  background-color: #212529;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  padding-left: 30px;
  padding-right: 30px; 
}

.header-container img {
  height: 33px;
}

.authorize-container {
  display: flex;
  align-items: center;
}

.authorize-container button {
  max-height: 30px;
  cursor: pointer;
  outline: none;
  background-color: #f7bd27;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  border: none;
  border-radius: 19px;
  padding: 6px 16px;
}