body {
  font-family: "Poppins", sans-serif !important;
  background-color: #2b2d34 !important;
  margin: 0;
}

p,
h2 {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  letter-spacing: 0;
  color: #fff;
}

.la-info-circle {
  margin: 0 4px;
  background: url("./views/icons/Group\ 270.svg") center;
  width: 16px;
  height: 16px;
}
